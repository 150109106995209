import { createClient } from '@sanity/client'; 
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID as string,
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN as string,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source: string) => builder.image(source);
