import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import csharp from '../assets/csharp.jpg'
import css from '../assets/css.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import angular from '../assets/Angular1.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import ngrx from '../assets/ngrx.svg'
import netcore from '../assets/coredotnet.svg';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  email,
  mobile,
  css,
  git,
  csharp,
  graphql,
  html,
  javascript,
  angular,
  react,
  redux,
  netcore,
  typescript,
  vue,
  profile,
  circle,
  logo,
  ngrx
};