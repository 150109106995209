import React, { ComponentType, ReactElement } from 'react';
import { NavigationDots } from '../components';

interface AppWrapProps {
  idName: string;
  classNames: string;
}

const AppWrap = <P extends object>(
  Component: ComponentType<P & AppWrapProps>,
  idName: string,
  classnames: string
) =>
  function HOC(props: P): ReactElement {
    return (
      <div id={idName} className={`app__container ${classnames}`}>
        {/* <SocialMedia /> */}
        <div className="app__wrapper app__flex">
          <Component {...props} idName={idName} classNames={classnames} />

          <div className="copyright">
            <p className="p-text">@2023 ANDREW</p>
            <p className="p-text">All rights reserved</p>
          </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
