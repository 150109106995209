import React, { ReactElement } from 'react';
import { motion, MotionProps } from 'framer-motion';

interface MotionWrapProps extends MotionProps {
  classnames: string;
}

const MotionWrap = <P extends object>(
  Component: React.ComponentType<P>,
  motionProps: MotionWrapProps
) => function HOC(props: P): ReactElement {
    return (
      <motion.div
        {...motionProps}
        className={`${motionProps.classnames} app__flex`}
      >
        <Component {...props} />
      </motion.div>
    );
};

export default MotionWrap;
